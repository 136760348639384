<template>
  <div class="tabs">
    <ul>
      <li v-for="y in orderedYears" 
        :key="y"
        :class="{'is-active': y === current}"
        @click="changeYear(y)"
      >
        <a>{{ y }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "YearTabs",
  computed: {
    orderedYears () {
      const currentYear = (new Date()).getFullYear();
      let years = [ currentYear ];
      if (this.$store.state.years) {
        const gt = this.$store.state.years.slice().filter(x => x > currentYear);
        years = years.concat(gt.sort((a, b) => { return a - b}))
      }
      return years
    }
  },
  data () {
    return {
      current: (new Date()).getFullYear()
    }
  },
  methods: {
    changeYear(year) {
      this.current = year;
      this.$emit('yearChange', year);
    }
  }
}
</script>