<template>
  <div class="note-form">
    <div v-if="!editing" class="note-text">
      <span class="marker">*</span>
      <span>{{ note.note }}</span>
    </div>
    <div v-else>
      <input type="text" v-model="newNote" />
    </div>
    <div>
      <nobr v-show="editing">
        <span class="icon" @click="save">
          <i class="fas fa-save"></i>
        </span>
        <span class="icon" @click="editing=false">
          <i class="fas fa-ban"></i>
        </span>
      </nobr>
      <nobr v-show="!editing">
        <span class="icon delete" @click="$emit('delete')">
          <i class="fas fa-trash-alt"></i>
        </span>
      </nobr>
    </div>
  </div>
</template>

<script>
export default {
   name: "Note",
   props: ['note'],
   data () {
     return {
       editing: false,
       newNote: this.note.note
     }
   },
   methods: {
     save() {
       this.editing = false;
     }
   }
}
</script>

<style scoped>
.note-text {
  display: flex;
}

.marker {
  margin-right: 5px;
  font-size: 1.7rem;
  font-weight: 700;
}

.note-form {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr auto;
}

input {
  width: 100%;
  font-size: .9em;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
}
</style>
