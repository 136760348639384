<template>
  <div class="home">
    <event-list />
  </div>
</template>

<script>
// @ is an alias to /src
import EventList from "../components/EventList"
import tokenExpiration from "../mixins/tokenExpiration"

export default {
  name: 'Home',
  mixins: [tokenExpiration],
  components: {
    EventList
  },
  mount() {
    console.log(this.$route.query);
  }
}
</script>

<style scoped>
.home {
  width: 100%;
}
</style>
