<template>
<div class="form-background">
  <div class="form-back">
    <div id="confirm-dialog">
      <div id="title">
        Confirm
      </div>
      <div id="message">
        <slot />
      </div>
      <div id="actions">
        <button id="cancel" class="button is-small" @click="$emit('close')">Cancel</button>
        <button id="confirm" class="button is-small" @click="$emit('confirm')">Confirm</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ConfirmDialog"
}
</script>

<style scoped>
.form-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}

.form-back {
  background-color: white;
  border-radius: 1rem;
  margin: auto;
  margin-top: 5rem;
  width: 40%;
}

@media screen and (max-width: 1200px) {
  .form-back {
    width: 50%;
  }
}
@media screen and (max-width: 900px) {
  .form-back {
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  .form-back {
    width: 75%;
  }
}
@media screen and (max-width: 375px) {
  .form-back {
    width: 90%;
  }
}

#title {
  padding: 10px;
  color: var(--bg-gray);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-size: 1.4em;
  background-color: var(--trmn-red);
  padding: 10px;
  color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

#message {
  margin: 10px 0;
  padding: 10px;
}

#actions {
  padding: 10px;
}

button:first-of-type {
  margin-right: 10px;
}

#actions {
  display: flex;
  justify-content: flex-end;
}
</style>
