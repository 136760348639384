<template>
  <div class="con-list panel" :class="{display: display}" @click.stop="">
    <p class="panel-heading">
      Con Reference
    </p>
    <div class="panel-block">
      <p class="control has-icons-left">
        <input
          class="input"
          type="text"
          placeholder="Search"
          v-model="searchTerm"
          @input="narrow"
        >
        <span class="icon is-left">
          <i class="fas fa-search" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="search-results">
      <a class="panel-block is-active" v-for="c in cons" :key="c.id" @click.stop="$emit('selection', c)">
        <span class="panel-icon">
          <i class="far fa-calendar"></i>
        </span>
        {{ c.name }} ({{ c.city }}, {{ c.state }})
      </a>
    </div>
  </div>
</template>

<script>
const debounce = function(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  name: "ConList",
  props: ['display'],
  data () {
    return {
      searchTerm: '',
      filter: ''
    }
  },
  computed: {
    cons () {
      if (this.$store.state.cons) {
        let re = new RegExp(this.filter.toLowerCase());
        return this.$store.state.cons
        .filter(c => re.test(`-${c.name}-${c.city}-${c.state}-${c.country}-`.toLowerCase()))
        .slice(0, 100);
      }
      return []
    }
  },
  methods: {
    narrow: debounce(function() {
      this.filter = this.searchTerm;
    }, 250)
  }
}
</script>

<style scoped>
.con-list {
  border: 1px solid #cccccc;
  height: inherit;
  max-height: 600px;
  width: 0;
  position: absolute;
  right: -50%;
  z-index: 100;
  background: white;
  transition: 0.5s ease-in all;
  overflow-y: scroll;
}

.display {
  right: 0;
  width: 50%;
}

.search-results {
  height: inherit;
}
</style>
