<template>
  <div class="multi-select">
    <button class="menu-btn button" @click="isShown=true">
      <span class="icon is-small">
        <i class="fas fa-caret-down"></i>
      </span>
      <span>{{ label }}</span>
    </button>
    <div class="menu" @click="isShown=false" v-show="isShown">
      <span class="menu-opt">
        <label for="empty" class="item" @click.stop="selected=[];$emit('input', selected)">
          <span for="empty">NONE SELECTED</span>
          <input type="checkbox" id="empty" checked disabled v-if="selected.length < 1" />
          <input type="checkbox" id="empty" disabled v-else />
          <span class="checkmark" />
        </label>
      </span>
      <span class="menu-opt" v-for="opt in options" :key="opt.value">
        <label :for="opt.value" class="item" @click.stop>
          <span :for="opt.value">{{ opt.label }}</span>
          <input type="checkbox"
            v-model="selected"
            :value="opt.value"
            :id="opt.value"
            @change="$emit('input', selected)"
          >
          <span class="checkmark" />
        </label>
      </span>
    </div>
    <div class="overlay" v-show="isShown" @click="isShown=false">
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiSelect",
  props: ['label', 'value', 'options'],
  data () {
    return {
      isShown: false,
      selected: []
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.0);
  z-index: 100;
}

.multi-select {
  position: relative;
}

.menu {
  background-color: white;
  box-shadow: 3px 3px 6px rgba(0,0,0,0.5);
  border-radius: 4px;
  position: absolute;
  width: auto;
  top: 105%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 101;
  width: auto;
  max-height: 50vh;
  overflow-y: scroll;
}

.menu-opt {
  display: inline-block;
  padding: 1px;
  white-space: nowrap;
}

button {
  color: var(--trmn-red);
}

label {
  margin-left: 6px;
}

input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.item {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 6px;
	cursor: pointer;
	font-size: 1em;
	height: 25px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: flex;
	align-items: center;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: white;
  border-radius: 3px;
}
.item:hover input ~ .checkmark {
	background-color: #c2c2c2;
}
.item input:checked ~ .checkmark {
	background-color: var(--trmn-red);
}
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
.item input:checked ~ .checkmark:after {
	display: block;
}
.item .checkmark:after {
	left: 10px;
	top: 6px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
</style>
