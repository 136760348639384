<template>
  <div class="container">
    <tooltip :tiptext="tooltip || label" style="text-align: right">
      <label :for="label">
        <span v-show="required">*</span>
        {{ label }}
      </label>
    </tooltip>
      <input
        :name="label"
        :readonly = "disabled ? disabled : false"
        :type="type ? type : 'text'"
        :value="value"
        :class="{disabled: disabled}"
        :required="type ? type==='email': false"
        @input="$emit('input', $event.target.value)"
      >
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div class="error" v-if="errMessage">
        {{ errMessage }}
      </div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip"

export default {
  name: "HorizontalInput",
  components: { Tooltip },
  props: ['value', 'label', 'type', 'disabled', 'errMessage', 'required', 'tooltip'],
}
</script>

<style scoped>
.error {
  color: red;
  font-size: .8rem;
  margin-top: -2px;
}
input {
  border: 0;
  border-bottom: 1px solid black;
  border-radius: 0;
  outline: none;
  /* line-height: 1.1em; */
  font-size: 0.9em;
  font-family: inherit;
}

input[type=text]:focus {
  border: 0;
  border-color: white;
  outline: none !important;
  border-bottom: 1px solid var(--trmn-red);
  border-radius: 0;
}

input.disabled {
  color: #888;
  border: 0;
}

label {
  text-align: right;
  margin-right: 10px;
}

.container {
  display: grid;
  grid-template-columns: minmax(100px, .25fr) minmax(200px, 1fr) minmax(100px, .25fr);
  font-size: 1.1em;
  margin-bottom: 0.4em;
}
</style>
