<template>
  <div id="event-list">
    <div class="form-background" v-show="formShow">
      <div class="form-back container">
        <Event ref="editForm" @close="formShow = false" />
      </div>
    </div>
    <Confirm
      ref="delConfirm"
      @close="delShow = null"
      @confirm="finishDel(delShow)"
      v-show="delShow"
    >
      Delete {{ delShow ? delShow.name : "" }}?
    </Confirm>
    <div class="container">
      <div class="page-top">
        <h1 class="is-size-3">Events</h1>

        <div class="controls" v-if="editor">
          <button
            class="button"
            id="add-event"
            @click="
              $refs.editForm.clearForm();
              formShow = true;
            "
          >
            <span class="icon is-small">
              <i class="fas fa-plus"></i>
            </span>
            <span>Add Event</span>
          </button>
        </div>
      </div>
      <year-tabs @yearChange="currentYear = $event" />

      <!-- <tag-selector v-model="selectedTags" /> -->
      <div class="filters">
        <span class="label">Filters</span>
        <multi-select
          label="AORs"
          v-model="filterOptions.aors"
          :options="
            tags
              .filter((t) => t.type === 'aor')
              .map((u) => {
                return { value: u.id, label: u.description };
              })
          "
        />
        <multi-select
          label="Themes"
          v-model="filterOptions.themes"
          :options="
            tags
              .filter((t) => t.type === 'theme')
              .map((u) => {
                return { value: u.id, label: u.tag };
              })
              .sort((a, b) => {
                return a.label.localeCompare(b.label);
              })
          "
        />
        <multi-select
          label="TRMN"
          v-model="filterOptions.trmn"
          :options="
            tags
              .filter((t) => t.type === 'trmn')
              .map((u) => {
                return { value: u.id, label: u.description };
              })
          "
        />
        <multi-select
          label="Status"
          v-model="filterOptions.status"
          :options="
            tags
              .filter((t) => t.type === 'status')
              .map((u) => {
                return { value: u.id, label: u.description };
              })
          "
        />
        <div class="filterCheck">
          <label for="hideOld" class="item" @click.stop>
            <input
              type="checkbox"
              v-model="hideOld"
              :value="hideOld"
              id="hideOld"
            />
            <span class="checkmark" />
            <span for="hideOld">Show Past events</span>
          </label>
        </div>
      </div>

      <div id="item-list">
        <event-row
          v-for="e in events"
          :key="'event-' + e.id"
          :event="e"
          :table="true"
          :editor="editor"
          @editing="editing(e)"
          @delete="del(e)"
        />
      </div>

      <nav
        class="pagination is-small"
        role="navigation"
        aria-label="pagination"
      >
        <ul class="pagination-list">
          <li>
            <a
              class="pagination-next"
              @click="changePage(1)"
              :class="{ 'btn-disabled': page === 1 }"
            >
              <i class="fas fa-angle-double-left"></i>
            </a>
          </li>
          <li>
            <a
              class="pagination-previous"
              @click="changePage(page - 1)"
              :class="{ 'btn-disabled': page === 1 }"
            >
              <i class="fas fa-angle-left"></i>
            </a>
          </li>
          <li v-for="p in pageList" :key="p">
            <a
              class="pagination-link"
              @click="changePage(p)"
              :class="{ 'is-current': page === p }"
              :aria-label="'Goto page ' + p"
            >
              {{ p }}
            </a>
          </li>
          <li>
            <a
              class="pagination-next"
              @click="changePage(page + 1)"
              :class="{
                'btn-disabled': page === pageList[pageList.length - 1],
              }"
            >
              <i class="fas fa-angle-right"></i>
            </a>
          </li>
          <li>
            <a
              class="pagination-next"
              @click="changePage(pageList[pageList.length - 1])"
              :class="{
                'btn-disabled': page === pageList[pageList.length - 1],
              }"
            >
              <i class="fas fa-angle-double-right"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import Event from "../views/Event";
import Confirm from "./Confirm";
// import EventCard from "./EventCard";
import EventRow from "./EventRow";
import YearTabs from "./YearTabs";
// import TagSelector from "./TagSelector";
import MultiSelect from "./MultiSelect";

export default {
  name: "EventList",
  // components: { Event, YearTabs, EventCard, TagSelector },
  components: { Event, YearTabs, EventRow, Confirm, MultiSelect },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      page: 1,
      count: 20,
      selectedTags: [],
      formShow: false,
      delShow: null,
      conListShow: false,
      hideOld: false,
      filterOptions: {
        aors: [],
        themes: [],
        trmn: [],
        status: [],
      },
    };
  },
  methods: {
    changeYear(yr) {
      this.currentYear = yr;
    },
    changePage(pg) {
      this.page = pg;
    },
    changeCount(ct) {
      this.count = ct;
    },
    editing(evt) {
      // console.log("EDITING", evt);
      this.$refs.editForm.setEvent(evt);
      this.formShow = true;
    },
    del(evt) {
      // console.log("DELETE", evt.id);
      this.delShow = evt;
      // this.$store.dispatch("deleteEvent", evt);
    },
    finishDel(evt) {
      // console.log("FINISH DEL", evt.id);
      const vm = this;
      this.$store
        .dispatch("deleteEvent", evt)
        .then(async function (response) {
          await vm.$store.commit("deleteEvent", response.data.id);
          vm.delShow = null;
          // console.log("RESPONSE", response.data.id);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
      // this.delShow = null;
    },
  },
  computed: {
    tags() {
      if (this.$store.state.tags) {
        let tags = [];
        for (const k in this.$store.state.tags) {
          tags.push({ id: parseInt(k), ...this.$store.state.tags[k] });
        }
        return tags;
      }
      return [];
    },
    editor() {
      const data = this.$store.state.user;
      if (data && data.user_type > 1) {
        return true;
      }

      return false;
    },
    pages() {
      const vm = this;
      if (this.$store.state.events) {
        // return Math.ceil(this.$store.state.events.filter(x => x.year === this.currentYear).length / this.count);
        let evts = this.$store.state.events.filter(
          (x) => x.year === this.currentYear
        );
        if (this.hideOld) {
          evts = evts.filter((e) => new Date(e.ending) >= new Date());
        }
        if (vm.filterOptions.aors.length > 0) {
          evts = evts.filter(function (x) {
            let result = false;
            vm.filterOptions.aors.forEach(function (t) {
              result |= x.tags.indexOf(t) !== -1;
            });
            return result;
          });
        }

        if (vm.filterOptions.themes.length > 0) {
          evts = evts.filter(function (x) {
            let result = false;
            vm.filterOptions.themes.forEach(function (t) {
              result |= x.tags.indexOf(t) !== -1;
            });
            return result;
          });
        }

        if (vm.filterOptions.trmn.length > 0) {
          evts = evts.filter(function (x) {
            let result = false;
            vm.filterOptions.trmn.forEach(function (t) {
              result |= x.tags.indexOf(t) !== -1;
            });
            return result;
          });
        }

        if (vm.filterOptions.status.length > 0) {
          evts = evts.filter(function (x) {
            let result = false;
            vm.filterOptions.status.forEach(function (t) {
              result |= x.tags.indexOf(t) !== -1;
            });
            return result;
          });
        }

        return Math.ceil(evts.length / this.count);
      }
      return 0;
    },
    pageList() {
      const links = 5;
      let totalPages = this.pages;
      let start = 1;
      let currentPage = this.page;

      let paging = [];

      if (currentPage < links / 2 + 1) {
        start = 1;

        // Don't go beyond the last page
      } else if (currentPage >= totalPages - links / 2) {
        start = Math.floor(totalPages - links + 1);
      } else {
        start = currentPage - Math.floor(links / 2);
      }

      for (let i = start; i <= start + Math.min(totalPages, links) - 1; i++) {
        paging.push(i);
      }
      return paging;
    },
    events() {
      const vm = this;
      if (this.$store.state.events) {
        const start = (this.page - 1) * this.count;
        let evts = this.$store.state.events
          .filter((x) => x.year === this.currentYear)
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          });

        let dated = evts.filter((x) => x.beginning !== "");
        if (!this.hideOld) {
          dated = dated.filter((e) => new Date(e.ending) > new Date());
        }
        let undated = evts.filter((x) => x.beginning === "");

        evts = dated
          .sort((a, b) => {
            let aDate = new Date(a.beginning);
            let bDate = new Date(b.beginning);
            return aDate < bDate ? -1 : 1;
          })
          .concat(undated);

        if (vm.filterOptions.aors.length > 0) {
          evts = evts.filter(function (x) {
            let result = false;
            vm.filterOptions.aors.forEach(function (t) {
              result |= x.tags.indexOf(t) !== -1;
            });
            return result;
          });
        }

        if (vm.filterOptions.themes.length > 0) {
          evts = evts.filter(function (x) {
            let result = false;
            vm.filterOptions.themes.forEach(function (t) {
              result |= x.tags.indexOf(t) !== -1;
            });
            return result;
          });
        }

        if (vm.filterOptions.trmn.length > 0) {
          evts = evts.filter(function (x) {
            let result = false;
            vm.filterOptions.trmn.forEach(function (t) {
              result |= x.tags.indexOf(t) !== -1;
            });
            return result;
          });
        }

        if (vm.filterOptions.status.length > 0) {
          evts = evts.filter(function (x) {
            let result = false;
            vm.filterOptions.status.forEach(function (t) {
              result |= x.tags.indexOf(t) !== -1;
            });
            return result;
          });
        }

        const end = Math.min(this.page * this.count - 1, evts.length);
        // console.log(start, end);
        return evts.slice(start, end);
      }
      return [];
    },
  },
};
</script>

<style scoped>
nav > ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.container {
  padding: 0 10px;
  width: 100%;
}

div#item-list {
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1rem;
}

.page-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

button#add-event {
  background-color: var(--trmn-red);
  color: white;
}

button#add-event:hover {
  background-color: white;
  color: var(--trmn-red);
}

.form-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
  overflow-y: scroll;
}

.form-back {
  background-color: white;
  padding: 20px;
  margin-top: 5rem;
  border-radius: 1rem;
}

.controls {
  position: relative;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  height: 100%;
}

.filters div {
  margin-right: 8px;
  align-items: center;
}

.filters span {
  display: inline-block;
  margin-right: 8px;
  margin: auto 0;
  margin-right: 8px;
  font-size: 1.2rem;
}

.btn-disabled {
  opacity: 0.4;
  cursor: default !important;
  pointer-events: none;
}

input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.item {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 1em;
  height: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid var(--trmn-red);
}
.item:hover input ~ .checkmark {
  background-color: #c2c2c2;
}
.item input:checked ~ .checkmark {
  background-color: var(--trmn-red);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.item input:checked ~ .checkmark:after {
  display: block;
}
.item .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.filterCheck {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  color: var(--trmn-red);
  border: 1px solid rgb(219, 219, 219);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 0 8px;
}

label {
  display: block;
  margin: auto 0;
}

label > span {
  font-size: 16px !important;
}

span.checkmark {
  margin-right: none !important;
}
</style>
