<template>
  <div id="app">
    <div id="top">
      <site-header />
      <site-nav />
    </div>
    <div id="center">
      <router-view/>
    </div>
    <div id="bottom">
      <site-footer />
    </div>
  </div>
</template>

<style>
@font-face {
font-family: 'Decalotype Light';
font-style: normal;
font-weight: normal;
src: local('Decalotype Light'), url('./assets/decalotype/Decalotype-Light.woff') format('woff');
}

@font-face {
  font-family: 'Incised Nord';
  font-style: normal;
  font-weight: normal;
  src: local('Incised Nord'), url('./assets/Incised-901-Nord-BT.ttf')
}

:root {
  --bg-gray: #272727;
  --trmn-red: #be2f26;
  --trmn-orange: #f2bb1a;
  --trmn-dark-orange: #c3812a;
  --trmn-black: #010101;
}

body {
  margin: 0;
}

#app {
  font-family: "Decalotype Light", Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fcfcfc;
  width: 100%;
}

#center {
  flex-grow: 1;
  height: 100%;
  padding: 10px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>
import SiteHeader from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';
import SiteNav from './components/SiteNav';
import jwt_decode from 'jwt-decode';

export default {
  name: "ConStack",
  components: {
    SiteHeader,
    SiteFooter,
    SiteNav
  },
  data () {
    return {
      events: []
    }
  },
  mounted() {
    this.$store.dispatch("fetchEvents");
    this.$store.dispatch("fetchTags");
    this.$store.dispatch("fetchCons");
    this.$store.dispatch("fetchCountries");
    const localUser = window.localStorage.getItem("constack");
    if ((localUser !== null) && (this.$store.state.user === null)) {
      const userData = JSON.parse(localUser);
      const token = userData.token;
      const expiration = new Date(jwt_decode(token)["exp"]*1000);
      const current = new Date();
      if(current > expiration) {
        window.localStorage.removeItem("constack");
        if(this.$route.path !== "/") {
          this.$router.push("/");
        }
        this.$store.commit("setUser", null);
        return;
      }
      // console.log("EXPIRES", expiration);
      this.$store.commit("setUser", userData);
    }
    // const user = this.$store.state.user;
    // if (this.$router.path !== "/" && (user === null || user.user_type < 4)) {
    //   this.$router.push("/").catch(() => {});
    // }
  }
}
</script>
