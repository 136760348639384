<template>
  <div class="container" @click="conListShow = false">
    <Confirm
      ref="delConfirm"
      @close="delShow = null"
      @confirm="finishDel()"
      v-show="delShow"
    >
      Delete {{ delShow ? delShow.note : "" }}?
    </Confirm>
    <div class="con-background">
      <con-list :display="conListShow" @selection="(e) => overwrite(e)" />
    </div>
    <div class="title">{{ add ? "Add" : "Edit" }} Event</div>
    <span class="reveal" @click.stop="conListShow = true" v-if="add">
      <i class="fas fa-angle-left"></i>
    </span>
    <form class="form">
      <!-- <horizontal-input v-model="evtId" label="ID" disabled="true" v-if="!add"/> -->
      <horizontal-input
        v-model="event.updated_at"
        label="Last Updated"
        disabled="true"
        v-if="!add"
      />
      <horizontal-input
        v-model="event.name"
        label="Name"
        :errMessage="nameError"
        required="true"
        :tooltip="'Name (required)'"
      />
      <horizontal-input v-model="event.location" label="Location" />
      <horizontal-input v-model="event.address" label="Address" />
      <horizontal-input v-model="event.city" label="City" />
      <horizontal-input v-model="event.state" label="State" />
      <horizontal-select
        v-model="event.country"
        label="Country"
        :choices="countries"
        keyField="code"
        valField="name"
      />
      <horizontal-input v-model="event.url" label="URL" type="url" />
      <horizontal-input
        v-model="event.beginning"
        label="Beginning"
        type="date"
        required="true"
        tooltip="Event begin date (required)"
      />
      <horizontal-input
        v-model="event.ending"
        label="Ending"
        type="date"
        :errMessage="dateError"
        required="true"
        tooltip="Event end date (required)"
      />
      <horizontal-select
        v-model="event.status"
        label="Status"
        :choices="statuses"
        keyField="id"
        valField="description"
        required="true"
        tooltip="Event Status (required)"
      />
      <horizontal-select
        v-model="event.aor"
        label="AOR"
        :choices="aors"
        keyField="id"
        valField="description"
        required="true"
        tooltip="TRMN AOR (required)"
      />
      <tag-select
        v-model="event.trmn"
        label="TRMN"
        :choices="trmn"
        keyField="id"
        valField="description"
        @data="(x) => addTag(x)"
      />
      <tag-select
        v-model="event.themes"
        label="Theme"
        :choices="themes"
        keyField="id"
        valField="tag"
        @data="(x) => addTag(x)"
      />
    </form>
    <div class="tag-list">
      <h1>Tags</h1>
      <div class="data-list field is-grouped multiline">
        <div
          class="control"
          v-for="t in tags.filter((x) => x.type === 'trmn')"
          :key="t.tag"
        >
          <div class="tags has-addons">
            <a class="tag is-trmn">{{ t.description }}</a>
            <a class="tag is-delete" @click="removeTag(t)"></a>
          </div>
        </div>

        <div
          class="control"
          v-for="t in tags.filter((x) => x.type === 'theme')"
          :key="t.tag"
        >
          <div class="tags has-addons">
            <a class="tag is-link">{{ t.tag }}</a>
            <a class="tag is-delete" @click="removeTag(t)"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="note-list" v-if="!add">
      <label class="label"> Notes </label>
      <div class="no-notes" v-if="this.notes.length === 0">No notes found</div>
      <div v-if="this.notes.length > 0">
        <note
          v-for="n in notes"
          class="note"
          :note="n"
          :key="n.id"
          @delete="deleteNote(n.id)"
        />
      </div>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <div class="note">
        <input type="text" v-model="newNote" />
        <span class="button is-small is-link" @click="addNote"> Add Note </span>
      </div>
      <span>&nbsp;</span>
    </div>
    <div class="button-container">
      <div class="buttons">
        <button class="button cancel" @click="$emit('close')">Cancel</button>
        <button class="button is-link" v-if="add" @click="save">Add</button>
        <button class="button is-link" v-else @click="save">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalInput from "../components/HorizontalInput";
import HorizontalSelect from "../components/HorizontalSelect";
import TagSelect from "../components/TagSelect";
import ConList from "../components/ConList";
import Note from "../components/Note";
import Confirm from "../components/Confirm";

const newEvent = {
  address: null,
  beginning: `${new Date().getFullYear()}-01-01`,
  city: null,
  country: "US",
  ending: `${new Date().getFullYear()}-01-01`,
  id: null,
  location: null,
  name: null,
  state: null,
  tags: [],
  status: "1558",
  url: null,
  year: new Date().getFullYear(),
  aor: null,
  trmn: null,
  themes: null,
};

require("dotenv").config();

export default {
  name: "EventEdit",
  components: {
    HorizontalInput,
    HorizontalSelect,
    TagSelect,
    ConList,
    Note,
    Confirm,
  },
  methods: {
    validateDates() {
      if (this.event.ending && this.event.beginning) {
        return new Date(this.event.ending) >= new Date(this.event.beginning);
      }
      return true;
    },
    validateName() {
      if (this.event.name) {
        return this.event.name !== "";
      }
      return false;
    },
    overwrite(con) {
      let evt = { ...newEvent };
      evt.city = con.city;
      evt.state = con.state;
      evt.country = con.country;
      evt.name = con.name;
      evt.url = con.url;
      evt.tags = con.tags.slice();
      this.setEvent(evt);
      this.conListShow = false;
    },
    clearForm() {
      // console.log("CLEAR!");
      this.event = { ...newEvent };
      this.event.tags = this.event.tags.slice(0, 0);
      this.nameError = null;
      this.dateError = null;
      this.newNote = null;
    },
    setEvent(evt) {
      this.event = { ...evt };
      const aorIds = this.aors.map((e) => {
        return parseInt(e.id);
      });
      const statusIds = this.statuses.map((e) => {
        return parseInt(e.id);
      });
      const aorXsect = evt.tags.filter((e) => {
        return aorIds.indexOf(e) > -1;
      });
      if (aorXsect.length > 0) {
        this.event["aor"] = `${aorXsect[0]}`;
      }
      const statusXsect = evt.tags.filter((e) => statusIds.indexOf(e) > -1);
      if (statusXsect.length > 0) {
        this.event["status"] = `${statusXsect[0]}`;
      }

      this.axios.get(`/events/${evt.id}/notes`).then((result) => {
        this.notes = result.data;
      });
    },
    addTag(x) {
      const val = parseInt(x);
      if (this.event.tags && this.event.tags.indexOf(val) === -1) {
        this.event.tags.push(val);
        console.log(this.event.tags);
      }
    },
    removeTag(x) {
      this.event.tags = this.event.tags.filter((t) => t !== x.id);
    },
    addNote() {
      const vm = this;
      this.$store
        .dispatch("postNote", { eventId: this.event.id, note: this.newNote })
        .then(async (response) => {
          console.log("NOTE GOT BACK", response);
          this.notes.push(response.data);
          vm.newNote = null;
          // await vm.$store.dispatch('updateEvent', this.event.id);
        })
        .catch((err) => {
          console.error("ADD NOTE", err);
        });
    },
    deleteNote(noteId) {
      this.delShow = { ...this.notes.filter((x) => x.id === noteId)[0] };
    },
    finishDel() {
      console.log("DELETE NOTE", this.delShow.id);
      const URL = `/notes/${this.delShow.id}`;
      const token = JSON.parse(window.localStorage.getItem("constack")).token;

      this.axios
        .delete(URL, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          console.log("DELETE RESPONSE", response);
          this.notes = this.notes.filter((x) => x.id !== response.data.id);
          this.delShow = null;
        })
        .catch((err) => {
          console.error("DELETE RESPONSE", err);
        });
    },
    save() {
      const vm = this;
      const nameValid = this.validateName();
      const datesValid = this.validateDates();
      if (!nameValid) {
        this.nameError = "Name is required";
      } else {
        this.nameError = null;
      }
      if (!datesValid) {
        this.dateError =
          "Ending date must the same or later than the beginning date";
      } else {
        this.dateError = null;
      }
      if (!nameValid || !datesValid) {
        return;
      }
      let evt = { ...this.event };
      evt.tags = evt.tags.map((t) => parseInt(t));
      evt.tags = evt.tags.filter((e) => e !== evt.aor);
      evt.tags = evt.tags.filter((e) => e !== evt.status);
      // console.log("DATES", evt.beginning, new Date(evt.beginning))
      delete evt.trmn;
      delete evt.themes;
      // console.log(JSON.stringify(evt));
      if (!this.event.year) {
        this.event.year = new Date().getFullYear();
      }
      if (this.event.id !== null) {
        evt.tags = [...new Set(evt.tags)];
        this.$store
          .dispatch("putEvent", evt)
          .then(async (response) => {
            await vm.$store.dispatch("updateEvent", response.data.id);
            console.log("PUT WORKED", response.data.id);
            vm.$emit("close");
          })
          .catch((err) => {
            console.error("PUT FAILED", err);
          });
      } else {
        evt.tags = [...new Set(evt.tags)];
        this.$store
          .dispatch("postEvent", evt)
          .then(async (response) => {
            await vm.$store.dispatch("updateEvent", response.data.id);
            console.log("POST WORKED", response.data);
            vm.$emit("close");
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
  data() {
    return {
      event: {
        address: null,
        beginning: `${new Date().getFullYear()}-01-01`,
        city: null,
        country: "US",
        ending: `${new Date().getFullYear()}-01-01`,
        id: null,
        location: null,
        name: null,
        state: null,
        tags: [],
        status: "1558",
        url: null,
        year: new Date().getFullYear(),
        aor: null,
      },
      notes: [],
      country: "US",
      conListShow: false,
      nameError: null,
      dateError: null,
      newNote: null,
      delShow: null,
    };
  },
  watch: {
    "event.beginning": function (newVal) {
      let d = new Date(`${newVal}T12:00:00.000Z`);
      this.event.year = d.getFullYear();
    },
  },
  computed: {
    validName() {
      return this.validateName();
    },
    validDates() {
      return this.validateDates();
    },
    item() {
      return this.data;
    },
    evtId() {
      return this.event.id ? this.event.id : "New";
    },
    evt() {
      if (this.edit && this.$store.state.events) {
        const res = this.$store.state.events
          .slice()
          .filter((x) => x.id === parseInt(this.evtId));
        if (res.length > 0) {
          return res[0];
        }
      }
      return {};
    },
    add() {
      return this.event.id === null;
    },
    edit() {
      return !this.add;
    },
    tagList() {
      if (this.$store.state.tags) {
        let tags = [];
        for (const id in this.$store.state.tags) {
          tags.push({ id: id, ...this.$store.state.tags[id] });
        }
        return tags;
      }
      return [];
    },
    tags() {
      let tags = [];
      if (this.$store.state && this.event.tags) {
        this.event.tags.map((t) => {
          tags.push({ id: t, ...this.$store.state.tags[t] });
        });
      }
      return tags;
    },
    themes() {
      return this.tagList
        .filter((x) => x && x.type === "theme")
        .sort((a, b) => {
          return a.tag.localeCompare(b.tag);
        });
    },
    statuses() {
      return this.tagList.filter((x) => x && x.type === "status");
    },
    trmn() {
      // MODIFY: don't display Admiralty House tag to ... (waiting on Patrick)
      var result = this.tagList.filter((x) => x && x.type === "trmn");
      return result;
    },
    aors() {
      return this.tagList.filter((x) => x && x.type === "aor");
    },
    countries() {
      if (this.$store.state.countries) {
        return this.$store.state.countries.slice().sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }
      return [];
    },
  },
};
</script>

<style scoped>
.tag-list {
  display: grid;
  grid-template-columns: minmax(100px, 0.25fr) minmax(200px, 1fr) minmax(
      100px,
      0.25fr
    );
  grid-template-rows: auto;
  height: 100%;
}

.tag-list > h1 {
  text-align: right;
  margin-right: 10px;
}

.tag-tag {
  background-color: green;
  color: white;
  padding: 0;
  margin: 0;
  line-height: 1em;
  font-size: 1em;
}

.tag:not(body).is-trmn {
  background-color: var(--trmn-red);
  color: #fff;
}

.tags .tag {
  margin-bottom: 10px;
}

.data-list {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.button-container {
  display: flex;
  justify-content: space-around;
}

.cancel {
  background-color: var(--trmn-red);
  color: white;
}

.container {
  position: relative;
  height: 100%;
}

.reveal {
  display: inline-block;
  cursor: pointer;
  box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 0px;
  margin-top: 40px;
  padding: 3px 6px;
  border-radius: 3px;
}

.note-list {
  display: grid;
  grid-template-columns: minmax(100px, 0.25fr) minmax(200px, 1fr) minmax(
      100px,
      0.25fr
    );
  font-size: 1.1em;
  margin-bottom: 0.4em;
}

label {
  font-size: 0.9em;
  font-weight: 400;
  text-align: right;
  margin-right: 10px;
}

ul.note-list {
  border: 1px solid red;
}

.note {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr auto;
}

input {
  border: 0;
  border-bottom: 1px solid black;
  border-radius: 0;
  outline: none;
  /* line-height: 1.1em; */
  font-size: 0.9em;
  font-family: inherit;
}

input[type="text"]:focus {
  border: 0;
  border-color: white;
  outline: none !important;
  border-bottom: 1px solid var(--trmn-red);
  border-radius: 0;
}
</style>
