<template>
    <div class="event" @click="handleClick">
      <div class="logo-container">
        <img class="aor-logo" :src="require('../assets/'+icon)" />
      </div>
      <div class="event-data">
        <div class="event-name">
          <span v-if="event.url !== ''">
            <a :href="event.url">{{ event.name }}</a>
            <span class="my-tag" v-show="status.length > 0 && status[0].tag==='new'">
              <event-tag tag="New" />
            </span>
            <span class="my-tag" v-show="status.length > 0 && status[0].tag==='uncert'">
              <event-tag tag="Uncertain" />
            </span>
            <br />
            </span>
          <span v-else>
            { event.name }}
            <span class="new-tag" v-show="status.length > 0 && status[0].tag==='new'">New</span>
            <br />
          </span>
          <span class="con-theme">{{ themes.map(t => t.tag).join("/") }}</span>
        </div>
        <div class="event-location">
          {{ event.location }}<br />
          {{ locale }}
        </div>
        <div class="event-dates">
          <nobr v-show="status.length > 0 && status[0].tag==='cancel'">
            <span class="canceled">Canceled</span>
          </nobr>
          <nobr v-show="status.length > 0 && status[0].tag!=='cancel'">
            {{ event.beginning || "UNK" }} to {{ event.ending || "UNK" }}
          </nobr>
          <br />

          <tooltip tiptext="TRMN Attending">
            <span class="icon" v-show="trmn.indexOf('att') > -1"><i class="enabled fas fa-check"></i></span>
            <span class="icon" v-show="trmn.indexOf('att') == -1"><i class="disabled fas fa-check"></i></span>
          </tooltip>

          <tooltip tiptext="TRMN Participating">
            <span class="icon" v-show="trmn.indexOf('part') > -1"><i class="enabled fas fa-hands-helping"></i></span>
            <span class="icon" v-show="trmn.indexOf('part') == -1"><i class="disabled fas fa-hands-helping"></i></span>
          </tooltip>

          <tooltip tiptext="TRMN Fleet Event">
            <img v-show="trmn.indexOf('fleet') > -1" class="status" :src="require('../assets/plain-flash.svg')" width="24"/>
            <img v-show="trmn.indexOf('fleet') == -1" class="status" :src="require('../assets/plain-flash-shadow.svg')" width="24"/>
          </tooltip>
          
          <tooltip tiptext="Admiralty House Event">
            <img v-show="trmn.indexOf('adm') > -1" class="status" :src="require('../assets/fla_32.png')" />
            <img v-show="trmn.indexOf('adm') == -1" class="status" :src="require('../assets/fla_bg_32.png')" />
          </tooltip>

          <tooltip tiptext="TRMN Themed">
            <img class="status" v-show="trmn.indexOf('theme') > -1" :src="require('../assets/rmn_32.png')" />
            <img v-show="trmn.indexOf('theme') == -1" class="status" :src="require('../assets/rmn_bg_32.png')" />
          </tooltip>

          <tooltip tiptext="David Weber Attending">
            <img v-show="trmn.indexOf('dw') > -1" class="status" :src="require('../assets/weber_32.png')" />
            <img v-show="trmn.indexOf('dw') == -1" class="status" :src="require('../assets/weber_bg_32.png')" />
          </tooltip>
        </div>
      </div>
      <div v-if="editor">
        <nobr>
          <span class="icon edit" @click.stop="$emit('editing')">
            <i class="fas fa-pencil-alt"></i>
          </span>
          <span class="icon delete" @click.stop="$emit('delete')">
            <i class="fas fa-trash-alt"></i>
          </span>
        </nobr>
      </div>
      <div class="notes" ref="notes">
        <h3>Notes</h3>
        <ul v-if="notes.length > 0">
          <li v-for="note in notes" :key="note.id">
            {{ note.note }}
          </li>
        </ul>
        <div v-else class="no-notes">
          No notes found
        </div>
      </div>
    </div>
</template>

<script>
import EventTag from "./EventTag";
import Tooltip from "./Tooltip";

export default {
  name: "EventRow",
  props: ["event", "table", "editor"],
  components: { EventTag, Tooltip },
  data() {
    return {
      notes: []
    }
  },
  methods: {
    handleClick() {
      // console.log("CLICK!", this.event.id);
      if (!this.loggedIn) {
        return;
      }
      const vm = this;
      this.axios.get(`/events/${this.event.id}/notes`).then((result) => {
        vm.notes = result.data;
      });
      this.$refs.notes.classList.toggle("notes-visible");
    }
  },
  computed: {
    tags() {
      return this.event.tags.map(t => this.$store.state.tags[t])
    },
    themes() {
      return this.event.tags.map(t => this.$store.state.tags[t]).filter(x => x && x.type === 'theme')
    },
    aor() {
      return this.event.tags.map(t => this.$store.state.tags[t]).filter(x => x && x.type === 'aor')
    },
    status() {
      return this.event.tags.map(t => this.$store.state.tags[t]).filter(x => x && x.type === 'status')
    },
    locale() {
      let l = []
      if (this.event.city) { l.push(this.event.city); }
      if (this.event.state) { l.push(this.event.state); }
      if (this.event.country) { l.push(this.event.country); }
      return l.join(", ");
    },
    trmn() {
      return this.event.tags
        .map(t => this.$store.state.tags[t])
        .filter(x => x && x.type === 'trmn')
        .map(x => x.tag)
    },
    loggedIn() {
      const data = this.$store.state.user;
      if (data) {
        return data;
      }

      return null;
    },
    icon() {
      const aor = this.event.tags.map(t => this.$store.state.tags[t]).filter(x => x && x.type === 'aor');
      const iconList = {
        "1F": "1.svg",
        "2F": "2.svg",
        "3F": "3.svg",
        "4F": "4.png",
        "6F": "6.svg",
        "7F": "7.png",
        "8F": "8.svg",
        "10F": "10.svg",
        "13F": "13.png",
        "VS": "victoria.svg",
        "SS": "sidemore.svg",
        "AS": "empty.png"
      };
      if (aor.length > 0) {
        return iconList[aor[0]['tag']];
      }
      return "empty.png";
    }
  }
}
</script>

<style scoped>
.tag {
  margin-left: 8px;
  font-variant: small-caps;
}

.canceled {
  font-variant: small-caps;
  color: var(--trmn-red);
  font-weight: 800;
}

.edit:hover {
  color: var(--trmn-orange);
  cursor: pointer;
}

span.delete:hover {
  background-color: var(--trmn-red);
}

.con-theme {
  font-variant: small-caps;
  color: var(--trmn-red);
}

.aor-logo {
  max-height: 48px;
  max-width: 48px;
  width: auto;
  height: auto;
  display: block;
  object-fit: contain;
}

.event-info {
  display: flex;
  flex-direction: row;
}

.event-name {
  display: flex;
  flex-direction: column;
}

.disabled {
  color: #ccc;
}

.enabled {
  color: purple;
}

img.status {
  margin-left: 3px;
  max-height: 20px;
}

.event {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(50px, 75px) 1fr minmax(50px, 75px);
  grid-gap: .25rem;
}

.event-data {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: .25rem;
}

.my-tag {
  font-size: .8em;
  font-weight: 600;
}

.dash {
  color: black;
}

.new-tag {
  color: green;
}

.uncert-tag {
  color: yellow;
}

.logo-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
}

.notes {
  grid-column: 1/3;
  display: none;
  padding-left: 16px;
}

.notes-visible {
  display: block;
}

.notes > ul {
  margin-left: 8px;
  list-style-type: circle;
}

.notes > h3 {
  font-weight: bold;
}

.notes > ul > li {
  margin-left: 16px;
}
</style>
