import jwt_decode from "jwt-decode";

var tokenExpiration = {
  beforeRouteLeave(to, from, next) {
    // console.log("BEFORE ROUTE LEAVE", from.path, to.path);
    let localUser = window.localStorage.getItem("constack");
    // check for expired login
    if (localUser !== null) {
      const userData = JSON.parse(localUser);
      const token = userData.token;
      const expiration = new Date(jwt_decode(token)["exp"]*1000);
      const current = new Date();
      if(current > expiration) {
        console.log("ROUTER expired")
        window.localStorage.removeItem("constack");
        localUser = null;
      } 
    }
    next();
  }
}

export default tokenExpiration;