<template>
  <footer id="footer">
    <div class="container">
      <span>
        &copy;{{ year }}&nbsp;<a href="http://trmn.org">The Royal Manticoran Navy</a>
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    year() {
      return (new Date()).getFullYear();
    }
  }
}
</script>

<style scoped>
footer {
  height: 60px;
  background-color: #363636;
  color: #ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4px;
}

a {
  color: var(--trmn-orange);
}

a:hover {
  color: var(--trmn-dark-orange);
}
</style>