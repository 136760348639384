import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    events: [],
    cons: [],
    countries: [],
    tags: [],
    profiles: [],
    years: [],
    user: null
  },
  getters: {
    maxId (state) {
      return Math.max(state.events.map(e => e.id));
    },
    identity () {
      const data = window.localStorage.getItem('constack');
      if (data) {
        return JSON.parse(data);
      }

      return {};
    }
  },
  mutations: {
    setUser(state, user) {
      this.state.user = user;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    setCons(state, cons) {
      state.cons = cons;
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setProfiles(state, profiles) {
      state.profiles = profiles;
    },
    setYears(state, years) {
      state.years = years;
    },
    updateEvent(state, evt) {
      state.events = state.events.filter(x => x.id !== evt.id);
      state.events.push(evt);
    },
    deleteEvent(state, evtId) {
      state.events = state.events.filter(x => x.id !== evtId);
    },
    deleteProfile(state, profileId) {
      state.profiles = state.profiles.filter(x => x.member_id !== profileId);
    },
    updateProfile(state, profile) {
      state.profiles = state.profiles.filter(x => x.member_id !== profile.member_id);
      state.profiles.push(profile);
    }
  },
  actions: {
    fetchEvents (context) {
      this._vm.axios.get('/events').then(response => {
        context.commit("setEvents", response.data.slice());
        const years = response.data.slice().map(x => x.year);
        context.commit("setYears", [...new Set(years)]);
      })
    },
    fetchTags (context) {
      this._vm.axios.get('/tags').then(response => {
        let tags = {}
        response.data.slice().forEach(t => {
          tags[t['id']] = {tag: t.tag, description: t.description, type: t.type}
        })
        context.commit("setTags", tags);
      })
    },
    fetchCons (context) {
      this._vm.axios.get('/cons').then(response => {
        context.commit("setCons", response.data.slice());
      })
    },
    fetchCountries (context) {
      this._vm.axios.get('/countries').then(response => {
        context.commit("setCountries", response.data.slice());
      })
    },
    fetchProfiles (context) {
      this._vm.axios.get('/profiles').then(response => {
        context.commit('setProfiles', response.data.slice());
      })
    },
    updateEvent (context, id) {
      // console.log("UPDATING EVENT", id);
      this._vm.axios.get(`/events/${id}`).then(response => {
        // console.log("ADD/UPDATE EVENT RESPONSE", response.data);
        context.commit("updateEvent", response.data);
      })
    },
    putEvent (context, evt) {
      const token = JSON.parse(window.localStorage.getItem("constack")).token;
      let method = this._vm.axios.put;
      let URL = `/events/${evt.id}`;
      return method(URL, evt, {headers: {Authorization: `Bearer ${token}`}});
    },
    postEvent (context, evt) {
      const token = JSON.parse(window.localStorage.getItem("constack")).token;
      let method = this._vm.axios.post;
      let URL = '/events';
      return method(URL, evt, {headers: {Authorization: `Bearer ${token}`}});
    },
    deleteEvent (context, evt) {
      const token = JSON.parse(window.localStorage.getItem("constack")).token;
      let method = this._vm.axios.delete;
      let URL = `/events/${evt.id}`;
      return method(URL, {headers: {Authorization: `Bearer ${token}`}, data: evt});
    },
    deleteProfile (context, profile) {
      const token = JSON.parse(window.localStorage.getItem("constack")).token;
      let method = this._vm.axios.delete;
      let URL = `/profiles/${profile.member_id}`;
      return method(URL, {headers: {Authorization: `Bearer ${token}`}, data: profile});
    },
    putProfile (context, profile) {
      const token = JSON.parse(window.localStorage.getItem("constack")).token;
      let method = this._vm.axios.put;
      let URL = `/profiles/${profile.member_id}`;
      return method(URL, profile, {headers: {Authorization: `Bearer ${token}`}});
    },
    postProfile (context, profile) {
      const token = JSON.parse(window.localStorage.getItem("constack")).token;
      let method = this._vm.axios.post;
      let URL = '/profiles';
      return method(URL, profile, {headers: {Authorization: `Bearer ${token}`}});
    },
    updateProfile (context, profileId) {
      this._vm.axios.get(`/profiles/${profileId}`).then(response => {
        context.commit("updateProfile", response.data);
      })
    },
    postNote (context, {eventId, note}) {
      
      const URL = `/events/${eventId}/notes`;
      const token = JSON.parse(window.localStorage.getItem("constack")).token;

      return this._vm.axios.post(URL, {eventId, note}, {headers: {Authorization: `Bearer ${token}`}});
    }
  },
  modules: {
  }
})
