<template>
  <span :class="`event-tag ${tag.toLowerCase()}`">
    <span class="tag-text">{{ tag.toLowerCase() }}</span>
  </span>
</template>

<script>
export default {
  name: "EventTag",
  props: ["tag"]
}
</script>

<style scoped>
.event-tag {
  margin-left: 3px;
  display: inline-block;
  background-color: #eee;
  border-radius: 6px;
  padding: 0 4px;
  font-variant: small-caps;
  align-content: center;
}

.tag-text {
  display: inline-block;
}

.new {
  background-color: rgba(32, 129, 195, 0.2);
  color: rgb(32, 129, 195);
}

.uncertain {
  background-color:rgba(242, 187, 26, 0.2);
  color: rgb(195, 129, 42);
}
</style>