<template>
  <div class="container">
    <tooltip :tiptext="tooltip || label" style="text-align: right">
      <label :for="label">
        <span v-show="required">*</span>
        {{ label }}
      </label>
    </tooltip>
    <select @change="$emit('input', $event.target.value)">
      <option value="-">None Selected</option>
      <option
        v-for="c in choices"
        :key="c[keyField]"
        :value="c[keyField]"
        :selected="value === c[keyField]"
      >
        {{ c[valField] }}
      </option>
    </select>
  </div>
</template>

<script>
import Tooltip from "./Tooltip"
export default {
  name: "HorizontalSelect",
  components: { Tooltip },
  props: ['value', 'label', 'choices', 'keyField', 'valField', 'required', 'tooltip']
}
</script>

<style scoped>
select {
  border: 0;
  border-bottom: 1px solid black;
  border-radius: 0;
  outline: none;
  font-size: 0.9em;
  font-family: inherit;
}

select:focus {
  border: 0;
  border-color: white;
  outline: none !important;
  border-bottom: 1px solid var(--trmn-red);
  border-radius: 0;
}

label {
  text-align: right;
  margin-right: 10px;
}

.container {
  display: grid;
  grid-template-columns: minmax(100px, .25fr) minmax(200px, 1fr) minmax(100px, .25fr);
  font-size: 1.1em;
  margin-bottom: 0.4em;
}
</style>
