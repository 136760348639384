import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
// import jwt_decode from 'jwt-decode';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/agents',
    name: 'Agents',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Agents.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  // {
  //   path: '/event',
  //   name: 'Event',
  //   component: () => import(/* webpackChunkName: "event" */ '../views/Event.vue'),
  //   children: [
  //     {
  //       path: 'add',
  //       component: () => import(/* webpackChunkName: "event" */ '../views/Event.vue')
  //     },
  //     {
  //       path: 'edit/:id',
  //       component: () => import(/* webpackChunkName: "event" */ '../views/Event.vue')
  //     }
  //   ]
  // },
  {
    path: '/profiles',
    name: 'Profiles',
    component: () => import(/* webpackChunkName: "profiles" */ '../components/ProfileList.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log("ROUTER BEFORE GUARD", from.path, to.path);
//   let localUser = window.localStorage.getItem("constack");
//   // check for expired login
//   if (localUser !== null) {
//     const userData = JSON.parse(localUser);
//     const token = userData.token;
//     const expiration = new Date(jwt_decode(token)["exp"]*1000);
//     const current = new Date();
//     if(current > expiration) {
//       console.log("ROUTER expired")
//       window.localStorage.removeItem("constack");
//       localUser = null;
//     } 
//   }
//   next();
//   // if (!localUser) {
//   //   if (from.path !== "/" && to.path === "/") {
//   //     next();
//   //   }

//   //   if (from.path !== "/" && to.path !== "/") {
//   //     next("/");
//   //   }

//   //   next();
//   // }
// })

export default router
