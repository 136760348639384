<template>
  <nav id="site-nav">
    <div class="container">
      <div id="menu-bar">
        <div class="menu-section">
          <div class="link">
            <router-link to="/">CONSTACK</router-link>
          </div>
          <div class="link" v-if="loggedIn">
            <router-link :to="{name: 'Agents'}">Agent List</router-link>
          </div>
        </div>
        <div class="menu-section">
          <div class="link" v-if="!loggedIn">
            <router-link to="/login">Login</router-link>
          </div>
          <div class="link" v-if="loggedIn" @click.prevent="showMenu=!showMenu">
            <a href="#" @click.prevent.stop="showMenu=!showMenu">
              {{ loggedIn.last_name }}, {{ loggedIn.first_name }}
              <span class="icon">
                <i class="fas fa-angle-down"></i>
              </span>
            </a>
          </div>
          <div class="submenu" v-if="showMenu">
            <div class="link" @click="profiles" v-if="loggedIn.user_type >= 4">Profiles</div>
            <div class="link" @click="logout">Logout</div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "SiteNav",
  data () {
    return {
      showMenu: false
    }
  },
  computed: {
    loggedIn() {
      const data = this.$store.state.user;
      if (data) {
        return data;
      }

      return null;
    }
  },
  methods: {
    logout () {
      window.localStorage.removeItem("constack");
      this.$store.commit('setUser', null);
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
      this.showMenu = false;
    },
    profiles () {
      if (this.$route.path !== "/profiles") {
        this.$router.push("/profiles");
      }
      this.showMenu = false;
    }
  }
}
</script>

<style scoped>
div {
  height: 100%;
}

div.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10px;
}

div.link:hover {
  background-color: #181818;
  color: var(--trmn-orange);
  cursor: pointer;
}

.menu-section {
  display: flex;
  flex-direction: row;
}

nav {
  height: 60px;
  background-color: var(--trmn-black);
  margin-top: 1px;
}

#menu-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

a {
  color: #eee
}

a:hover {
  color: inherit;
}

.submenu {
  color: black;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  width: 100%;
  height: auto;
  top: 60px;
  border: 1px solid rgba(39, 39, 39, 0.5);
  z-index: 100;
}

.submenu > a {
  padding: 10px;
  border: none;
  color: black;
}

.submenu > .link:hover {
  color: var(--trmn-red);
  background-color: white;
}
</style>