<template>
  <div class="container">
    <label :for="label">{{ label }}</label>
    <div class="blah">
      <select ref="options" @change="$emit('input', $event.target.value)">
        <option value="-">None Selected</option>
        <option
          v-for="c in choices"
          :key="c[keyField]"
          :value="c[keyField]"
        >
          {{ c[valField] }}
        </option>
      </select>
      <span class="add icon is-small" @click="(value && value !== '-') ? $emit('data', $refs.options.value) : null">
        <i class="fas fa-plus"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TagSelect",
  props: ['value', 'label', 'choices', 'keyField', 'valField']
}
</script>

<style scoped>
select {
  border: 0;
  border-bottom: 1px solid black;
  border-radius: 0;
  outline: none;
  font-size: 0.9em;
  font-family: inherit;
}

select:focus {
  border: 0;
  border-color: white;
  outline: none !important;
  border-bottom: 1px solid var(--trmn-red);
  border-radius: 0;
}

label {
  text-align: right;
  margin-right: 10px;
}

.container {
  display: grid;
  grid-template-columns: minmax(100px, .25fr) minmax(200px, 1fr) minmax(100px, .25fr);
  font-size: 1.1em;
  margin-bottom: 0.4em;
}

.blah {
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(25px, .1fr);
}

.add {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin: 0 6px;
}

.add:hover {
  color: var(--trmn-red);
}
</style>
